import store from "@/store/index";
import useHelpers from "@/composables/useHelpers";
import useActiveToggler from "@/composables/useActiveToggler";
import i18nSetup from "@/services/i18nSetup";
import { ref, reactive, computed, onMounted } from "vue";

import { GET_STATS_FILTERS } from "@/modules/widget/store/types";
import { filter } from "lodash";

const { slice } = useHelpers();
const { toggleDropdown, isActiveDropdown } = useActiveToggler();
const { i18n } = i18nSetup();
const translate = i18n.global.t;
const activeResult = ref("status");
const today = new Date();
const priorDate = new Date(new Date().setDate(today.getDate() - 7));
priorDate.setHours(0, 0, 0, 0);
const startTime = priorDate;
const endTime = today;
const sentTime = ref(null);
const fullname = ref("");
const email = ref("");
const messageSender = ref("");
const zoomFactor = ref(null);
const eventStart = ref(null);
const eventFinish = ref(null);

const filters = reactive({
  activeResult: "status",
  startTime: null,
  endTime: null,
  sentTime: null,
  zoomFactor: null,
  fullname: "",
  email: "",
  messageSender: "",
  activeContact: {
    id: 0,
    title: "Office",
  },
  activeDepartment: {
    id: 0,
    title: "Department",
  },
  activeWidget: {
    id: 0,
    title: "Widget",
  },
  activeService: {
    id: 0,
    key: null,
    title: "Service",
  },
  refresh: true,
});

const statsFilters = computed(() => store.getters["stats/statsFilters"]);

const activeContact = reactive({
  id: 0,
  title: "Office",
});

const activeDepartment = reactive({
  id: 0,
  title: "Department",
});

const activeWidget = reactive({
  id: 0,
  title: "Widget",
});

const activeService = reactive({
  id: 0,
  title: "Service",
});

export default function useFilters() {
  const widgets = computed(() => statsFilters.value?.widgets);

  const contacts = computed(() => {
    if (filters.activeWidget.widget_id == 0)
      return statsFilters.value?.contacts;

    return statsFilters.value?.contacts.filter((contact) => {
      return contact.widget_id == filters.activeWidget.widget_id;
    });
  });

  const offices = computed(() => contacts.value);

  const departments = computed(() => {
    let filteredDepartments = statsFilters.value?.departments;

    if (filters.activeContact.id != 0) {
      filteredDepartments = filteredDepartments.filter((department) => {
        return department.contact_id == filters.activeContact.id;
      });
    }

    if (filters.activeWidget.widget_id != 0) {
      filteredDepartments = filteredDepartments.filter((department) => {
        return department.widget_id == filters.activeWidget.widget_id;
      });
    }

    return filteredDepartments;
  });

  const departmentNames = computed(() => departments.value);

  const findOffice = (officeId) => {
    const office = contacts.value?.find(
      (contact) => contact.id == officeId
    )?.name;
    return slice(office, 15);
  };

  const findDepartment = (departmentId) => {
    const department = departments.value?.find(
      (department) => department.id == departmentId
    )?.name;
    return slice(department, 15);
  };

  const services = computed(() => {
    return [
      {
        id: 1,
        key: "AUDIOCALL",
        name: translate("label.widget.multilanguage.modal.webcall"),
      },
      {
        id: 2,
        key: "EMAIL",
        name: translate("label.widget.multilanguage.modal.email"),
      },
      {
        id: 3,
        key: "MESSAGE",
        name: translate("label.widget.multilanguage.modal.message"),
      },
      {
        id: 4,
        key: "CALLBACK",
        name: translate("label.widget.multilanguage.modal.callback"),
      },
      {
        id: 5,
        key: "VIDEOCALL",
        name: translate("label.widget.multilanguage.modal.videocall"),
      },
    ];
  });

  const findService = (serviceId) => {
    let key = null;
    services.value?.forEach((service) => {
      if (serviceId == service.id) key = service.mainSettings.key;
    });
    return key;
  };

  const emailData = computed(() => {
    let data = statsFilters.value?.emailData;

    if (filters.activeWidget.widget_id != 0) {
      data = data.filter(
        (data) => data.widget_id == filters.activeWidget.widget_id
      );
    }

    if (filters.activeContact.id != 0) {
      data = data.filter((data) => data.contact_id == filters.activeContact.id);
    }

    if (filters.activeDepartment.id != 0) {
      data = data.filter(
        (data) => data.department_id == filters.activeDepartment.id
      );
    }

    return data;
  });

  const fullnames = computed(() =>
    emailData.value?.map((data) => data.fullname)
  );

  const emails = computed(() => emailData.value?.map((data) => data.email));

  const messageSenders = computed(() => {
    let data = statsFilters.value?.messagesData;

    if (filters.activeWidget.widget_id != 0) {
      data = data.filter(
        (data) => data.widget_id == filters.activeWidget.widget_id
      );
    }

    if (filters.activeContact.id != 0) {
      data = data.filter((data) => data.contact_id == filters.activeContact.id);
    }

    if (filters.activeDepartment.id != 0) {
      data = data.filter(
        (data) => data.department_id == filters.activeDepartment.id
      );
    }

    return data?.map((data) => data.sender);
  });

  const results = computed(() => {
    return ["Failed", "Missed", "Completed"];
  });

  const setZoomFactor = (value) => {
    filters.zoomFactor = value;
    zoomFactor.value = value;
  };

  const setActiveResource = ({ resource, type, id }) => {
    if (type == "activeContact") {
      activeContact.id = resource.id;
      activeContact.title = resource.title;
    }
    if (type == "activeDepartment") {
      activeDepartment.id = resource.id;
      activeDepartment.title = resource.title;
    }
    if (type == "activeWidget") {
      activeWidget.id = resource.id;
      activeWidget.title = resource.title;
    }
    if (type == "activeService") {
      activeService.id = resource.id;
      activeService.key = resource.key;
      activeService.title = resource.title;
    }
    toggleDropdown(id);
  };

  const setActiveResourceFilter = ({ resource, type, id }) => {
    if (type == "activeContact") {
      filters.activeContact.id = resource.id;
      filters.activeContact.title = resource.title;
    }
    if (type == "activeDepartment") {
      filters.activeDepartment.id = resource.id;
      filters.activeDepartment.title = resource.title;
    }
    if (type == "activeWidget") {
      filters.activeWidget.id = resource.id;
      filters.activeWidget.title = resource.title;
    }
    if (type == "activeService") {
      filters.activeService.id = resource.id;
      filters.activeService.key = resource.key;
      filters.activeService.title = resource.title;
    }
    if (isActiveDropdown(id)) toggleDropdown(id);
  };

  const clearAllFilters = () => {
    filters.activeResult = "status";

    setActiveResourceFilter({
      resource: { id: 0, title: "Office" },
      type: "activeContact",
      id: "filters.contact",
    });

    setActiveResourceFilter({
      resource: { id: 0, title: "Department" },
      type: "activeDepartment",
      id: "filters.department",
    });

    setActiveResourceFilter({
      resource: { id: 0, title: "Widget" },
      type: "activeWidget",
      id: "filters.widget",
    });

    setActiveResourceFilter({
      resource: { id: 0, title: "Service" },
      type: "activeService",
      id: "filters.service",
    });

    filters.fullname = "";
    filters.email = "";
    filters.sentTime = null;
    filters.messageSender = "";
    filters.startTime = priorDate;
    filters.endTime = today;
  };

  const getStatsFilters = () => {
    store.dispatch(`stats/${GET_STATS_FILTERS}`);
  };

  onMounted(() => {
    getStatsFilters();
  });

  return {
    email,
    emails,
    filters,
    endTime,
    offices,
    services,
    results,
    fullname,
    widgets,
    sentTime,
    contacts,
    startTime,
    fullnames,
    eventFinish,
    eventStart,
    findOffice,
    findService,
    statsFilters,
    activeResult,
    departments,
    messageSender,
    activeContact,
    activeWidget,
    activeService,
    messageSenders,
    findDepartment,
    setZoomFactor,
    clearAllFilters,
    activeDepartment,
    departmentNames,
    setActiveResource,
    setActiveResourceFilter,
  };
}
