<template>
  <ul :class="['my-3', tab, { visible: show }]">
    <Item
      :title="
        slice(filters.activeService.title, 15) ||
        $t('label.profile.profile-payment.body.transactions.title-service')
      "
      id="filters.service"
      v-if="tab == '' || tab == 'map'"
    >
      <dropdown
        classes="w-48 flex justify-start top-6"
        v-if="isActiveDropdown('filters.service')"
        id="filters.service"
      >
        <dropdown-item
          @click.prevent="
            setActiveResourceFilter({
              resource: {
                id: 0,
                title: $t(
                  'label.profile.profile-payment.body.transactions.title-service'
                ),
              },
              type: 'activeService',
              id: 'filters.service',
            })
          "
          class="dropdown-item"
          :classes="filters.activeService.id == 0 ? 'dropdown-item-active' : ''"
        >
          {{
            $t("label.profile.profile-payment.body.transactions.title-service")
          }}
        </dropdown-item>

        <dropdown-item
          class="dropdown-item"
          v-for="(service, index) in services"
          :key="index"
          @click.prevent="
            setActiveResourceFilter({
              resource: {
                id: service.id,
                key: service.key,
                title: service.name,
              },
              type: 'activeService',
              id: 'filters.service',
            })
          "
          :title="service.name"
          :classes="
            filters.activeService.id == service.id ? 'dropdown-item-active' : ''
          "
        >
          {{ slice(service.name, 15) }}
        </dropdown-item>
      </dropdown>
    </Item>

    <Item
      :title="
        slice(filters.activeWidget.title, 12) ||
        $t('text.widget.pages.stats.widget')
      "
      id="filters.widget"
    >
      <dropdown
        classes="w-48 flex justify-start top-6"
        v-if="isActiveDropdown('filters.widget')"
        id="filters.widget"
      >
        <dropdown-item
          class="dropdown-item"
          @click.prevent="
            setActiveResourceFilter({
              resource: { id: 0, title: 'Widget', widget_id: 0 },
              type: 'activeWidget',
              id: 'filters.Widget',
            })
          "
          :classes="filters.activeWidget.id == 0 ? 'dropdown-item-active' : ''"
        >
          {{ $t("text.widget.pages.stats.widget") }}
        </dropdown-item>

        <dropdown-item
          class="dropdown-item"
          v-for="(widget, index) in widgets"
          :key="index"
          @click.prevent="
            setActiveResourceFilter({
              resource: {
                id: widget.id,
                title: widget.name,
                widget_id: widget.widget_id,
              },
              type: 'activeWidget',
              id: 'filters.widget',
            })
          "
          :title="widget.name"
          :classes="
            filters.activeWidget.id == widget.id ? 'dropdown-item-active' : ''
          "
        >
          {{ slice(widget.name, 15) }}
        </dropdown-item>
      </dropdown>
    </Item>

    <Item
      :title="
        slice(filters.activeContact.title, 12) ||
        $t('text.widget.pages.stats.office')
      "
      id="filters.office"
    >
      <dropdown
        classes="w-48 flex justify-start top-6"
        v-if="isActiveDropdown('filters.office')"
        id="filters.office"
      >
        <dropdown-item
          @click.prevent="
            setActiveResourceFilter({
              resource: { id: 0, title: 'Office' },
              type: 'activeContact',
              id: 'filters.office',
            })
          "
          class="dropdown-item"
          :classes="filters.activeContact.id == 0 ? 'dropdown-item-active' : ''"
        >
          {{ $t("text.widget.pages.stats.office") }}
        </dropdown-item>

        <dropdown-item
          class="dropdown-item"
          v-for="(office, index) in offices"
          :key="index"
          @click.prevent="
            setActiveResourceFilter({
              resource: { id: office.id, title: office.name },
              type: 'activeContact',
              id: 'filters.office',
            })
          "
          :title="office.name"
          :classes="
            filters.activeContact.id == office.id ? 'dropdown-item-active' : ''
          "
        >
          {{ slice(office.name, 15) }}
        </dropdown-item>
      </dropdown>
    </Item>

    <Item
      :title="
        slice(filters.activeDepartment.title, 12) ||
        $t('text.widget.pages.stats.department')
      "
      id="filters.department"
    >
      <dropdown
        classes="w-48 flex justify-left top-6"
        v-if="isActiveDropdown('filters.department')"
        id="filters.department"
      >
        <dropdown-item
          class="dropdown-item"
          @click.prevent="
            setActiveResourceFilter({
              resource: { id: 0, title: 'Department' },
              type: 'activeDepartment',
              id: 'filters.department',
            })
          "
          :classes="
            filters.activeDepartment.id == 0 ? 'dropdown-item-active' : ''
          "
        >
          {{ $t("text.widget.pages.stats.department") }}
        </dropdown-item>

        <dropdown-item
          class="dropdown-item"
          v-for="(department, index) in departmentNames"
          :key="index"
          @click.prevent="
            setActiveResourceFilter({
              resource: { id: department.id, title: department.name },
              type: 'activeDepartment',
              id: 'filters.department',
            })
          "
          :title="department.name"
          :classes="
            filters.activeDepartment.id == department.id
              ? 'dropdown-item-active'
              : ''
          "
        >
          {{ slice(department.name, 15) }}
        </dropdown-item>
      </dropdown>
    </Item>

    <template v-if="tab == 'emailss'">
      <!-- <Item
        :title="
          slice(filters.fullname, 12) || $t('text.widget.pages.stats.fullname')
        "
        id="filters.fullname"
      >
        <dropdown
          classes="w-48 flex justify-left top-6"
          v-if="isActiveDropdown('filters.fullname')"
          id="filters.fullname"
          style="overflow-y: auto; max-height: 20rem"
        >
          <dropdown-item
            class="dropdown-item"
            :classes="filters.fullname == '' ? 'dropdown-item-active' : ''"
            @click.prevent="
              setFilter({ type: 'fullname', value: '', id: 'filters.fullname' })
            "
          >
            {{ $t("text.widget.pages.stats.fullname") }}
          </dropdown-item>

          <dropdown-item
            class="dropdown-item"
            v-for="(name, index) in fullnames"
            :key="index"
            :title="name"
            :classes="name == filters.fullname ? 'dropdown-item-active' : ''"
            @click.prevent="
              setFilter({
                type: 'fullname',
                value: name,
                id: 'filters.fullname',
              })
            "
          >
            {{ slice(name, 15) }}
          </dropdown-item>
        </dropdown>
      </Item> -->

      <Item
        :title="slice(filters.email, 12) || $t('text.widget.pages.stats.email')"
        id="filters.email"
      >
        <dropdown
          classes="w-48 flex justify-left top-6"
          v-if="isActiveDropdown('filters.email')"
          id="filters.email"
          style="overflow-y: auto; max-height: 20rem"
        >
          <dropdown-item
            class="dropdown-item"
            :classes="filters.email == '' ? 'dropdown-item-active' : ''"
            @click.prevent="
              setFilter({ type: 'email', value: '', id: 'filters.email' })
            "
          >
            {{ $t("text.widget.pages.stats.email") }}
          </dropdown-item>

          <dropdown-item
            class="dropdown-item"
            v-for="(mail, index) in emails"
            :key="index"
            :title="mail"
            :classes="email == mail ? 'dropdown-item-active' : ''"
            @click.prevent="
              setFilter({ type: 'email', value: mail, id: 'filters.email' })
            "
          >
            {{ slice(mail, 15) }}
          </dropdown-item>
        </dropdown>
      </Item>
    </template>

    <template v-if="tab == 'messagess'">
      <Item
        :title="
          slice(filters.messageSender, 12) ||
          $t('text.widget.pages.stats.sender')
        "
        id="filters.sender"
      >
        <dropdown
          classes="w-48 flex justify-left top-6"
          v-if="isActiveDropdown('filters.sender')"
          id="filters.sender"
          style="overflow-y: auto; max-height: 20rem"
        >
          <dropdown-item
            class="dropdown-item"
            :classes="filters.messageSender == '' ? 'dropdown-item-active' : ''"
            @click.prevent="
              setFilter({
                type: 'messageSender',
                value: '',
                id: 'filters.sender',
              })
            "
          >
            {{ $t("text.widget.pages.stats.sender") }}
          </dropdown-item>

          <dropdown-item
            class="dropdown-item"
            v-for="(sender, index) in messageSenders"
            :key="index"
            :title="sender"
            :classes="
              filters.sender == messageSender ? 'dropdown-item-active' : ''
            "
            @click.prevent="
              setFilter({
                type: 'messageSender',
                value: sender,
                id: 'filters.sender',
              })
            "
          >
            {{ slice(sender, 15) }}
          </dropdown-item>
        </dropdown>
      </Item>
    </template>

    <template v-if="tab == 'map'">
      <Datepicker
        v-model="filters.startTime"
        placeholder="Start Date"
        inputClassName="dp-custom-input"
        autoApply
        :closeOnAutoApply="false"
        format="yyyy-MM-dd hh:mm"
      />

      <Datepicker
        v-model="filters.endTime"
        placeholder="End Date"
        inputClassName="dp-custom-input"
        autoApply
        :closeOnAutoApply="false"
        format="yyyy-MM-dd hh:mm"
      />
    </template>

    <template v-if="['calls', 'videoCalls','emails', 'messages', 'callRequests'].includes(tab)">
      <Datepicker
        v-model="filters.startTime"
        placeholder="Start Date"
        inputClassName="dp-custom-input"
        autoApply
        :closeOnAutoApply="false"
        format="yyyy-MM-dd hh:mm"
      />

      <Datepicker
        v-model="filters.endTime"
        placeholder="End Date"
        inputClassName="dp-custom-input"
        autoApply
        :closeOnAutoApply="false"
        format="yyyy-MM-dd hh:mm"
      />
    </template>

    <template v-if="tab == 'call-requests'">
      <Item
        :title="filters.activeResult || $t('text.widget.pages.stats.status')"
        id="filters.status"
      >
        <dropdown
          classes="w-48 flex justify-left top-6"
          v-if="isActiveDropdown('filters.status')"
          id="filters.status"
        >
          <dropdown-item
            class="dropdown-item"
            :class="{
              'dropdown-item-active':
                filters.activeResult.toLowerCase() == 'status',
            }"
            @click.prevent="
              setFilter({
                type: 'activeResult',
                value: 'status',
                id: 'filters.status',
              })
            "
          >
            {{ $t("text.widget.pages.stats.status") }}
          </dropdown-item>
          <dropdown-item
            v-for="result in results"
            :key="result"
            class="dropdown-item"
            @click.prevent="
              setFilter({
                type: 'activeResult',
                value: result,
                id: 'filters.status',
              })
            "
            :class="{
              'dropdown-item-active':
                filters.activeResult.toLowerCase() == result.toLowerCase(),
            }"
          >
            {{ result }}
          </dropdown-item>
        </dropdown>
      </Item>
    </template>
  </ul>
</template>

<script setup>
import { ref } from "vue";
// import lodash from "lodash";
import Item from "./Item";
import useActiveToggler from "@/composables/useActiveToggler";
import useFilters from "@/modules/widget/composables/Reports/useFilters";
import useHelpers from "@/composables/useHelpers";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

defineProps({
  tab: {
    required: true,
    type: String,
  },
  show: {
    required: false,
    type: Boolean,
    default: false,
  },
});


// const showStartDate = ref(false);

const {
  contacts,
  departments,
  fullnames,
  emails,
  messageSenders,
  results,
  widgets,
  activeService,
  offices,
  departmentNames,
  services,
  filters,
  setActiveResource,
  setActiveResourceFilter,
} = useFilters();

const times = [
  {
    key: "today",
    title: "Today",
  },
  {
    key: "week",
    title: "This week",
  },
  {
    key: "month",
    title: "This month",
  },
];

const setFilter = ({ type, value, id }) => {
  filters[type] = value;
  if (isActiveDropdown(id)) toggleDropdown(id);
};

const { isActiveDropdown, toggleDropdown } = useActiveToggler();
const { slice } = useHelpers();
</script>

<style scoped lang="scss">
.dp__pointer {
  border: 0 !important;
  background: 0 !important;
  color: #2c3e50;
}

input.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg.date-input::placeholder {
  color: #2c3e50;
}
.dp__input_wrap {
  width: 200px !important;
}
.dp__input {
  border: none !important;
}

.dropdown {
  left: 0 !important;
  &::after {
    left: 1px !important;
    right: 100%;
  }
}

.dropdown-item {
  font-size: 14px;
  text-align: left;
  &-active {
    font-weight: bold !important;
  }
}

ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  opacity: 0;
  visibility: hidden;
  gap: 1rem;
  align-items: center;
}

ul.visible {
  opacity: 1;
  visibility: visible;
}

ul.call-requests {
  grid-template-columns: repeat(6, 1fr);
}
</style>
