<template>
  <li class="inline-block cursor-pointer relative">
    <a
      href="#"
      class="font-semibold flex items-center gap-x-2"
      @click.prevent="toggleDropdown(id)"
    >
      <span>{{ title }}</span>
      <Icon
        name="angle-down"
        class="cursor-pointer"
        width="12"
        height="12"
        color="black"
      />
    </a>

    <slot></slot>
  </li>
</template>

<script>
import useActiveToggler from '@/composables/useActiveToggler'
export default {
  props: {
    id: String,
    title: String
  },
  setup () {
    const { toggleDropdown } = useActiveToggler()
    return {
      toggleDropdown
    }
  }
}
</script>

<style lang="scss" scoped></style>
